import React, { useCallback, useEffect } from 'react'
import {Routes, Route} from 'react-router-dom';
import Login from '../pages/Login';
import Home from '../pages/Home';
import UpcomingEvents from '../pages/UpcomingEvents';
import Event from '../pages/Event';
import Players from '../pages/Players';
import ProtectedRoute from '../router/ProtectedRoute';
import Rules from '../pages/Rules';
import Prices from '../pages/Prices';
import { useAuth } from '../context/AuthContext';
import api from './API';
import SuccessBar from '../successbar/SuccessBar';
import Mail from '../pages/Mail';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import Gamemodes from '../pages/Gamemodes';
import Airsoftbomb from '../pages/Airsoftbomb';
import Textblockeditor from '../pages/Textblockeditor';
import Member from '../pages/Member';

function Wrapper() {
  const {setAuth} = useAuth();

  const initializeApiInterceptors = useCallback(() => {
    api.interceptors.response.use(
      (response) => {
        if (response) {
          setAuth(true);
          return response;
        }
      },
      (error) => {
        if (error.response.status === 401) {
          setAuth(false);
        } else if (error.response.status === 403) {
          error.response.data = "Dazu bist du nicht berechtigt.";
          return Promise.reject(error);
        } else {
          return Promise.reject(error);
        }
      }
    );
  }, [setAuth]);

  useEffect(() => {
    initializeApiInterceptors();
    api.get("/Login/CheckAuthentication")
      .then(() => {
        // Handle response if needed
      })
      .catch((error) => {
        // Handle error if needed
      });
  }, [setAuth, initializeApiInterceptors]);

  return (
    <React.Fragment>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path='/' element={<ProtectedRoute><Home /></ProtectedRoute>} />
        <Route path='/upcoming-events' element={<ProtectedRoute><UpcomingEvents /></ProtectedRoute>} />
        <Route path='/event/:eventId/:eventDate' element={<ProtectedRoute><Event /></ProtectedRoute>} />
        <Route path='/users/:playerId' element={<ProtectedRoute><Players /></ProtectedRoute>} />
        <Route path='/rules' element={<ProtectedRoute><Rules /></ProtectedRoute>} />
        <Route path='/prices' element={<ProtectedRoute><Prices /></ProtectedRoute>} />
        <Route path='/mail/:eventId/:playerId' element={<ProtectedRoute><Mail /></ProtectedRoute>} />
        <Route path='/textblockeditor' element={<ProtectedRoute><Textblockeditor /></ProtectedRoute>} />
        <Route path='/forgotpassword' element={<ForgotPassword />} />
        <Route path='/resetpassword' element={<ResetPassword />} />
        <Route path='/gamemodes' element={<ProtectedRoute><Gamemodes /></ProtectedRoute>} />
        <Route path='/airsoftbomb' element={<ProtectedRoute><Airsoftbomb /></ProtectedRoute>} />
        <Route path='/member' element={<ProtectedRoute><Member /></ProtectedRoute>} />
      </Routes>
      <SuccessBar />
    </React.Fragment>
  )
}

export default Wrapper