import React from 'react';
import './ListSymbols.css';
import Icon from '../home/Icon';

function ListSymbol({icon = '', visible = false, type = 0}) {

  return (
    <React.Fragment>
      {visible && 
        <Icon className={`listSymbolIcon ${type === 0 ? 'accent' : 'negative'}`} icon={icon} />
      }
    </React.Fragment>
  )
}

export default ListSymbol