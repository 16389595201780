import React, { useEffect, useState } from 'react';
import Navbar from '../nav/Navbar';
import '../event/EventList.css';
import EventList from '../event/EventList';
import { useParams } from 'react-router-dom';
import api from '../wrapper/API';
import CreateForm from '../form/CreateForm';
import { useSuccessBar } from '../context/SuccessBarContext';
import CustomTogglebox from '../input/CustomTogglebox';
import { motion } from 'framer-motion';
import Popup from '../popup/Popup';
import { usePopup } from '../context/PopupContext';
import ListTextItem from '../list/ListTextItem';
import ListHeader from '../list/ListHeader';
import ListItem from '../list/ListItem';
import './Event.css';
import CustomComboBox from '../input/CustomComboBox';
import Icon from '../home/Icon';

function Event() {
  document.documentElement.style.setProperty('--defaultMarginContainer', '10px')
  const [posts, setPosts] = useState([]);
  const { eventId } = useParams();
  const { eventDate } = useParams();
  const {openPopup, closePopup} = usePopup();
  const [selectPlayerData, setSelectePlayerData] = useState([{}]);
  const [selectedGroup, setSelectedGroup] = useState(0);
  const [maxGroup, setMaxGroup] = useState(0);

  useEffect(() => {
    api
      .get(`/EventPlayer/GetParticipants?eventId=${eventId}`)
      .then((response) => {
        setPosts(response.data);
        if (response.data.length > 0) {
          setMaxGroup(Math.max(...response.data.map(post => post.group)));
        }
      })
      .catch(() => {
        
      });
  }, [eventId, setMaxGroup, setPosts]);

  //Add Form
  const {startSuccessBarLoading, startSuccessBarSuccess, closeSuccessBar} = useSuccessBar();
  const [formError, setFormError] = useState(null);

  const [nachname, setNachname] = useState("");
  const [vorname, setVorname] = useState("");
  const [useReferencePlayer, setUseReferencePlayer] = useState(false);
  const [referencePlayerName, setReferencePlayerName] = useState("");
  const [referencePlayerId, setReferencePlayerId] = useState(null);
  const [referencePlayerSelectionActive, setReferencePlayerSelectionActive] = useState(false);
  const [useReservation, setReservation] = useState(false);
  const [useWaitlist, setUseWaitlist] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [withEquipment, setWithEquipment] = useState(false);

  const handleAddNewPlayerClick = (event) => {
    event.preventDefault();
    if (useReservation) {
      addReservationToEvent();
    } else if (useWaitlist) {
      addWaitlistToEvent();
    } else {
      addPlayerToEvent();
    }
  };

  const handleAddSelectedPlayerClick = (event, playerId) => {
    const foundSelectedPlayer = selectPlayerData.find(post => post.id === playerId);
    closePopup();
    resetForm();
    addSpezificPlayerToEvent(
      foundSelectedPlayer.lastname,
      foundSelectedPlayer.firstname,
      foundSelectedPlayer.email,
      foundSelectedPlayer.phone,
      withEquipment,
      referencePlayerId,
      selectedGroup
    );
  };

  const resetForm = () => {
    setFormError(null);
    setNachname("");
    setVorname("");
    setEmail("");
    setPhone("");
    setWithEquipment("");
    setReferencePlayerId(null);
    setUseReferencePlayer(false);
    setReferencePlayerName("");
    setReservation(false);
    setUseWaitlist(false);
    setSelectedGroup(0);
  };

  const addPlayerToEvent = () => {
    addSpezificPlayerToEvent(nachname, vorname, email, phone, withEquipment, referencePlayerId, selectedGroup)
  }

  const addSpezificPlayerToEvent = (pnachname, pvorname, pemail, pphone, pequipment, preferencePlayerId, pgroup) => {
    return new Promise((resolve, reject) => {
      if (useReferencePlayer && referencePlayerName === "") {
        setFormError("Es wurde kein Referenzspieler ausgewählt.");
        reject("Es wurde kein Referenzspieler ausgewählt.");
        return;
      }
  
      startSuccessBarLoading("Spieler wird hinzugefügt");
      const requestBody = {
        eventId: eventId,
        lastname: pnachname ? pnachname : "",
        firstname: pvorname ? pvorname : "",
        email: pemail ? pemail : "",
        phone: pphone ? pphone : "",
        equipment: pequipment ? 1 : 0,
        referencePlayerId: preferencePlayerId ? preferencePlayerId : null,
        group: pgroup ? pgroup : 0
      }
  
      api.post('/EventPlayer/AddParticipant', requestBody)
        .then((response) => {
          if (response.data.length === 1) {
            startSuccessBarSuccess("Spieler hinzugefügt");
            const updatedPosts = [...posts, response.data[0]];
            setMaxGroup(Math.max(...updatedPosts.map(post => post.group)));
            setPosts(updatedPosts);
            resetForm();
            resolve(response.data[0]);
          } else if (response.data.length > 1) {
            closeSuccessBar();
            setSelectePlayerData(response.data);
            openPopup('selectplayer', 1);
            resolve(response.data);
          }
        })
        .catch(error => {
          closeSuccessBar();
          setFormError(error.response.data);
        });
    });
  };

  const addReservationToEvent = () => {
    startSuccessBarLoading("Reservierung wird hinzugefügt");
    const requestBody = {
      eventId: eventId,
      lastname: nachname ? nachname : "",
      firstname: vorname ? vorname : "",
      email: email ? email : "",
      phone: phone ? phone : "",
      equipment: withEquipment ? 1 : 0,
      group: selectedGroup ? selectedGroup : 0
    }
    
    api.post('/EventPlayer/AddReservation', requestBody)
      .then((response) => {
        startSuccessBarSuccess("Reservierung hinzugefügt");
        const updatedPosts = [...posts, response.data];
        setMaxGroup(Math.max(...updatedPosts.map(post => post.group)));
        setPosts(updatedPosts);
        resetForm();
      })
      .catch(error => {
        closeSuccessBar();
        setFormError(error.response.data);
      })
  };

  const addWaitlistToEvent = () => {
    startSuccessBarLoading("Wartelisteneintrag wird hinzugefügt");
    const requestBody = {
      eventId: eventId,
      lastname: nachname,
      firstname: vorname,
      email: email,
      phone: phone,
      equipment: withEquipment ? 1 : 0,
      group: selectedGroup ? selectedGroup : 0
    }
    
    api.post('/EventPlayer/AddToWaitlist', requestBody)
      .then((response) => {
        startSuccessBarSuccess("Wartelisteneintrag hinzugefügt");
        const updatedPosts = [...posts, response.data];
        setMaxGroup(Math.max(...updatedPosts.map(post => post.group)));
        setPosts(updatedPosts);
        resetForm();
      })
      .catch(error => {
        closeSuccessBar();
        setFormError(error.response.data);
      })
  }

  const handleWithEquipmentChange = (event) => {
    setWithEquipment(event.target.checked); 
  };

  const handleUseReferencePlayerChange = (event) => {
    setUseReferencePlayer(event.target.checked);
    setUseWaitlist(false);
    setReservation(false);
  };

  const handleIsDummyPlayerChange = (event) => {
    setReservation(event.target.checked);
    setUseWaitlist(false);
    setUseReferencePlayer(false);
  };

  const handleIsWaitlistPlayerChange = (event) => {
    setUseWaitlist(event.target.checked);
    setReservation(false);
    setUseReferencePlayer(false);
  };

  const handleListItemClick = (event, playerId, lastname, firstname) => {
    if (!useReferencePlayer || !referencePlayerSelectionActive) return;
    event.preventDefault();
    setReferencePlayerName(lastname + ", " + firstname);
    setReferencePlayerId(playerId);
    setReferencePlayerSelectionActive(false);
    openPopup('confirmreferenceplayer', playerId);
  };

  const useReferencePlayerVariants = {
    initial: {
      scale: 1
    },
    hover: {
      scale: 1.02,
      cursor: "pointer"
    },
    click: {
      scale: 0.98,
      transition: {
        duration: 0.001
      }
    }
  }

  return (
    <React.Fragment>
      <Navbar eventsSelected={true} extended={true} />
      <div className={"generalContainer"}>
        <EventList 
          posts={posts} setPosts={setPosts} 
          eventId={eventId} onListItemClick={handleListItemClick} eventDate={eventDate} addPlayer={addSpezificPlayerToEvent}
          maxGroup={maxGroup} setMaxGroup={setMaxGroup} resetCreateForm={resetForm}
        />
        <CreateForm onSubmit={handleAddNewPlayerClick} icon="person_add" error={formError} tooltip={"Spieler zum Spieltag hinzufügen"}>
          <input value={nachname} onChange={e => setNachname(e.target.value)} type="text" placeholder="Nachname" name="lastname" />
          <input value={vorname} onChange={e => setVorname(e.target.value)} type="text" placeholder="Vorname" name="firstname" />
          {useReferencePlayer ? 
            <React.Fragment>
              <input value={referencePlayerName} onChange={e => setReferencePlayerName(e.target.value)} type="text" placeholder="Referenzspieler" name="referenceplayer" readOnly required />
              <motion.input 
                type="button" 
                value={referencePlayerSelectionActive ? "Abbrechen" : "Referenzspieler auswählen"} 
                name="choosereferenceplayer"
                onClick={() => setReferencePlayerSelectionActive(prev => !prev)}
                variants={useReferencePlayerVariants}
                initial="initial"
                whileHover="hover"
                whileTap="click"
              />
            </React.Fragment> : <React.Fragment>
              <input value={email} onChange={e => setEmail(e.target.value)} type="text" placeholder="E-Mail" name="email" />
              <input value={phone} onChange={e => setPhone(e.target.value)} type="text" placeholder="Telefonnummer" name="phone" />
            </React.Fragment>
          }
          {!referencePlayerSelectionActive && 
            <React.Fragment>
              <CustomTogglebox onChange={handleWithEquipmentChange} checked={withEquipment} placeholder={'Leihausrüstung'} />
              {!useReservation && !useWaitlist &&
                <React.Fragment>
                  <CustomTogglebox onChange={handleUseReferencePlayerChange} checked={useReferencePlayer} placeholder={'Referenzspieler benutzen'} />
                </React.Fragment>
              }
              {!useWaitlist && !useReferencePlayer &&
                <CustomTogglebox onChange={handleIsDummyPlayerChange} checked={useReservation} placeholder={'Reservierung'} />
              }
              {!useReservation && !useReferencePlayer &&
                <CustomTogglebox onChange={handleIsWaitlistPlayerChange} checked={useWaitlist} placeholder={'Warteliste'} />
              }
            </React.Fragment>
          }
          <CustomComboBox placeholder={maxGroup <= 5 ? "Team" : ""} selected={selectedGroup} onStatusChange={setSelectedGroup}>
          <Icon key={-1} className={`eventCreateFormGroupIcon group0`} icon={`close`} />
            {
              Array.from({ length: maxGroup }, (_, index) => (
                <Icon key={index} className={`eventCreateFormGroupIcon group${index + 1}`} icon={`counter_${index + 1}`} />
              ))
            }
            <Icon key={maxGroup + 1} className={`eventCreateFormGroupIcon group${maxGroup + 1}`} icon={`counter_${maxGroup + 1}`} />
          </CustomComboBox>
        </CreateForm>
      </div>
      <Popup name='confirmreferenceplayer' confirmText={'Ok'} withCancel type="info">
        <p>Der Referenzspieler wurde gesetzt.</p>
      </Popup>
      <Popup name='selectplayer' confirmText={'Ok'} type="warning" withCancel withConfirm={false}>
        <p className={"selectPlayerTitle"}>Es wurden mehrere Spieler gefunden. Welcher Spieler soll hinzugefügt werden?</p>
        <div className={'selectPlayerWrapper'}>
          <ListHeader gridTemplateColumns={'repeat(2, 1fr)'} size={'small'}>
            <ListTextItem isHeader size={"small"}>Nach</ListTextItem>
            <ListTextItem isHeader size={"small"}>Vor</ListTextItem>
          </ListHeader>
          {selectPlayerData && 
            selectPlayerData.map((selectedPlayer, index) => (
              <ListItem 
                key={selectedPlayer.id}
                identifier={selectedPlayer.id}
                gridTemplateColumns={'repeat(2, 1fr)'}
                memo={selectedPlayer.memo}
                index={index}
                size={'small'}
                onClick={handleAddSelectedPlayerClick}
              >
                <ListTextItem>{selectedPlayer.lastname}</ListTextItem>
                <ListTextItem>{selectedPlayer.firstname}</ListTextItem>
              </ListItem>
            )
          )}
        </div>
      </Popup>
    </React.Fragment>
  );
}

export default Event;