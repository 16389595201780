import React from 'react';
import './List.css';
import Tooltip from '../tooltip/Tooltip';

function ListColorExtension({group = 0}) {
  return (
    group > 0 &&
    <React.Fragment>
      <Tooltip className={"listColorExtensionContainer"} text={`Team ${group}`}>
        <svg className={`listColorExtensionItem group${group}`} width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 2V12C1 16 3 18 7 18H17L1 2Z"/>
        </svg>
      </Tooltip>
    </React.Fragment>
  )
}

export default ListColorExtension