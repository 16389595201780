import React, { useState } from 'react';
import './Tooltip.css';
import { AnimatePresence, motion } from 'framer-motion';

const Tooltip = ({ text, children, className }) => {
  const [isVisible, setIsVisible] = useState(false);

  const showTooltip = (event) => {
    setIsVisible(true);
  };

  const hideTooltip = () => {
    setIsVisible(false);
  };

  const tooltipVariants = {
    hidden: {
      y: -10,
      opacity: 0
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 0.2
      }
    }
  }

  return (
    <div className={`tooltip-container ${className}`} onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      {children}
      <AnimatePresence>
        {isVisible && text !== '' && (
          <motion.div
            key={"tooltip"}
            className="tooltip"
            variants={tooltipVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            {text}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Tooltip;