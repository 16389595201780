import React, { useEffect, useRef, useState } from 'react';
import api from '../wrapper/API';
import { useSuccessBar } from '../context/SuccessBarContext';
import Textfield from '../input/Textfield';
import SubmitButton from '../input/SubmitButton';
import Popup from '../popup/Popup';
import { usePopup } from '../context/PopupContext';
import '../styles/MainContainer.css';

function EditPlayerScreen({playerId}) {

  //Add Form
  const [post, setPost] = useState({});
  const {startSuccessBarLoading, startSuccessBarSuccess, closeSuccessBar, successBarState} = useSuccessBar();
  const [formError, setFormError] = useState(null);
  const {openPopup, closePopup, popupData} = usePopup();
  const [isLoaded, setIsLoaded] = useState(false);

  const [nachname, setNachname] = useState("");
  const [vorname, setVorname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const nachnameRef = useRef();
  const vornameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const memoInputRef = useRef();

  useEffect(() => {
    if (playerId !== '0') {
      api
        .get(`/Player/GetPlayerById?playerId=${playerId}`)
        .then((response) => {
          startSuccessBarSuccess("Spieler geladen.");
          setNachname(response.data.lastname);
          setVorname(response.data.firstname);
          setEmail(response.data.email);
          setPhone(response.data.phone);
          setPost(response.data);
          setIsLoaded(true);
        })
        .catch((error) => {
          closeSuccessBar();
          setFormError(error.response.data);
          openPopup("error", 1);
        });
    }
  }, [playerId, closeSuccessBar, openPopup, startSuccessBarSuccess]);

  const handleAddNewPlayerClick = (event) => {
    startSuccessBarLoading("Spieler wird erstellt")
    event.preventDefault();
    const requestBody = {
      lastname: nachname,
      firstname: vorname,
      email: email,
      phone: phone
    }
    
    api.post('/Player/Create', requestBody)
      .then((response) => {
        startSuccessBarSuccess("Spieler erstellt");
        setFormError(null);
        setNachname("");
        setVorname("");
        setEmail("");
        setPhone("");
      })
      .catch(error => {
        closeSuccessBar();
        setFormError(error.response.data);
        openPopup("error", 1);
      })
  };

  function reset() {
    setNachname("");
    setVorname("");
    setEmail("");
    setPhone("");
    setIsLoaded(false);
    setPost({});
  }

  const handleSearchClick = () => {
    startSuccessBarLoading("Spieler wird Gesucht.");
    const requestBody = {
      lastname: nachname,
      firstname: vorname,
      email: email,
      phone: phone,
    }

    api
      .get(`/Player/GetPlayer?lastname=${requestBody.lastname}&firstname=${requestBody.firstname}&email=${requestBody.email}&phone=${encodeURIComponent(requestBody.phone)}`)
      .then((response) => {
        startSuccessBarSuccess("Spieler geladen.");
        setPost(response.data);
        setNachname(response.data.lastname);
        setVorname(response.data.firstname);
        setEmail(response.data.email);
        setPhone(response.data.phone);
        setIsLoaded(true);
      })
      .catch((error) => {
        closeSuccessBar();
        setFormError(error.response.data);
        openPopup("error", 1);
      });
  };

  const handleResetClick = () => {
    reset();
  };

  const handleEditPlayerClick = () => {
    startSuccessBarLoading("Änderungen werden übernommen")
    const requestBody = {
      playerId: post.id,
      firstname: vorname,
      lastname: nachname,
      email: email,
      phone: phone
    }

    api.put(`/Player/EditPlayer?playerId=${requestBody.playerId}&statusId=${requestBody.statusId}&password=${requestBody.password}&firstname=${requestBody.firstname}&lastname=${requestBody.lastname}&email=${requestBody.email}&phone=${encodeURIComponent(requestBody.phone)}`)
      .then((response) => {
        startSuccessBarSuccess("Änderungen übernommen");
        reset();
      })
      .catch((error) => {
        closeSuccessBar();
        setFormError(error.response.data);
        openPopup("error", requestBody.playerId);
      })
  };

  const handleEditMemoClick = () => {
    openPopup('editplayermemo', post.id);
  };

  const handleEditMemoConfirm = () => {
    startSuccessBarLoading("Änderungen werden übernommen")
    const requestBody = {
      playerId: popupData.identifier,
      memo: memoInputRef.current.value
    }

    api.put(`/Player/AddMemo?playerId=${requestBody.playerId}&memo=${requestBody.memo}`)
      .then((response) => {
        startSuccessBarSuccess("Änderungen übernommen");
        const updatedPost = post;
        updatedPost.memo = response.data.memo;
        setPost(updatedPost);
      })
      .catch(error => {
        closeSuccessBar();
        setFormError(error.response.data);
        openPopup("playererror", requestBody.playerId);
      })
  };

  const handleCallClick = () => {
    if (isLoaded) {
      window.location.href = `tel:${phoneRef.current.value}`;
    }
  };

  const handleSendMailClick = () => {
    if (isLoaded) {
      window.location.href = `mailto:${emailRef.current.value}`;
    }
  };

  const handleDeletePlayerClick = () => {
    openPopup('warningdeleteplayer', post.id);
  };

  const handleConfirmDeletePlayer = () => {
    startSuccessBarLoading("Spieler wird gelöscht");
    api.delete(`/Player/Delete?playerId=${popupData.identifier}`)
      .then(() => {
        startSuccessBarSuccess("Spieler wurde gelöscht");
        reset();
      })
      .catch((error) => {
        closeSuccessBar();
        setFormError(error.response.data);
        openPopup("error", popupData.identifier);
      })
    closePopup();
  }

  return (
    <React.Fragment>
      <main className={"generalBigContainer"}>
        <div className={"innerContainer"}>
          <div className={"generalInformationContainer"}>
            <label className={"innerContainerLabel"}>Grundlagen</label>
            <Textfield ref={nachnameRef} icon={"id_card"} type={"name"} name={"lastname"} placeholder={"Nachname"} onChange={(e) => setNachname(e.target.value)} value={nachname} />
            <Textfield ref={vornameRef} icon={"face"} fill type={"name"} name={"firstname"} placeholder={"Vorname"} onChange={(e) => setVorname(e.target.value)} value={vorname} />
            <Textfield ref={emailRef} icon={"alternate_email"} type={"email"} name={"email"} placeholder={"E-Mail"} onChange={(e) => setEmail(e.target.value)} value={email} />
            <Textfield ref={phoneRef} icon={"call"} type={"phone"} fill name={"phone"} placeholder={"Telefonnummer"} onChange={(e) => setPhone(e.target.value)} value={phone} />
            <div className={"buttonsIconContainer"}>
              {isLoaded && successBarState !== 'loading' && 
                <React.Fragment>
                  <SubmitButton icon={"replay"} onClick={handleResetClick} styletype={"smallround"} tooltip={"Alle Felder leeren"} />
                  <SubmitButton icon={"call"} onClick={handleCallClick} styletype={"smallround"} type={2} tooltip={"Anrufen"} />
                  <SubmitButton icon={"email"} onClick={handleSendMailClick} styletype={"smallround"} tooltip={"Email senden"} />
                  <SubmitButton icon={"delete_sweep"} onClick={handleDeletePlayerClick} styletype={"smallround"} type={1} tooltip={"Spieler dauerhaft löschen"} />
                  {(nachname !== post.lastname || vorname !== post.firstname ||
                    email !== post.email || phone !== post.phone) &&
                    <SubmitButton icon={"check"} onClick={handleEditPlayerClick} styletype={"smallnormal"} />
                  }
                </React.Fragment>
              }
              {!isLoaded && successBarState !== 'loading' &&
                <React.Fragment>
                  <SubmitButton icon={"search"} onClick={handleSearchClick} styletype={"smallnormal"} tooltip={"Spieler suchen"} />
                  <SubmitButton icon={"person_add"} onClick={handleAddNewPlayerClick} styletype={"smallround"} tooltip={"Spieler erstellen"} />
                </React.Fragment>
              }
            </div>
          </div>
          <div className={"sideContainer"}>
            <label className={"innerContainerLabel"}>Allgemeine Spielermemo</label>
            <div className={"memoOutput"}>
              {post.memo ? post.memo.split('\\n').map((zeile, index) => (
              index % 2 === 0 ? <h4 key={index}>{zeile}</h4> : <p key={index}>{zeile}</p>
              )) : ''}
            </div>
            {isLoaded && successBarState !== 'loading' &&
              <div className={"buttonsIconContainer"}>
                <SubmitButton icon={"add"} onClick={handleEditMemoClick} styletype={"smallround"} tooltip={"Memoeintrag hinzufügen"} />
              </div>
            }
          </div>
        </div>
      </main>
      <Popup name='editplayermemo' confirmText={'Übernehmen'} onConfirm={handleEditMemoConfirm} withCancel type="info">
        <h3>Memo:</h3>
        <textarea ref={memoInputRef} className={"memoInput"} maxLength={200} placeholder='Memoeintrag hinzufügen'></textarea>
      </Popup>
      <Popup name='error' confirmText='Ok' headerText='Error' type="error">
        <p>{formError}</p>
      </Popup>
      <Popup name='warningdeleteplayer' confirmText='Bestätigen' headerText='Warnung'
        type="warning" onConfirm={handleConfirmDeletePlayer} withCancel
      >
        <p>Möchtest du <strong>{`${post.lastname}, ${post.firstname}`}</strong> wirklich dauerhaft löschen?</p>
      </Popup>
    </React.Fragment>
  )
}

export default EditPlayerScreen