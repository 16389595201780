import React, { useEffect, useState } from 'react';
import Navbar from '../nav/Navbar';
import './Member.css';
import api from '../wrapper/API';
import CreateForm from '../form/CreateForm';
import { useSuccessBar } from '../context/SuccessBarContext';
import Popup from '../popup/Popup';
import { usePopup } from '../context/PopupContext';
import ListTextItem from '../list/ListTextItem';
import ListHeader from '../list/ListHeader';
import ListItem from '../list/ListItem';
import MemberList from '../member/MemberList';
import CustomComboBox from '../input/CustomComboBox';
import StatusDot from '../status/StatusDot';
import CustomTogglebox from '../input/CustomTogglebox';

function Member() {
  document.documentElement.style.setProperty('--defaultMarginContainer', '10px')
  const [posts, setPosts] = useState([]);
  const {openPopup, closePopup} = usePopup();
  const [selectPlayerData, setSelectePlayerData] = useState([{}]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedPlayerMemberStatus, setSelectedPlayerMemberStatus] = useState(0);
  const [password, setPassword] = useState("");
  const [applyForFutureEvents, setApplyForFutureEvents] = useState(true);

  useEffect(() => {
    handleSelectPlayerMemberDateConfirm();
  }, []);

  const openYearSelect = () => {
    openPopup("selectplayermemberyear", 1);
  };

  //Add Form
  const {startSuccessBarLoading, startSuccessBarSuccess, closeSuccessBar} = useSuccessBar();
  const [formError, setFormError] = useState(null);

  const [nachname, setNachname] = useState("");
  const [vorname, setVorname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const handleAddSelectedPlayerClick = (playerId) => {
    const foundSelectedPlayer = selectPlayerData.find(post => post.id === playerId);
    closePopup();
    resetForm();
    addSpezificMember(
      foundSelectedPlayer.lastname,
      foundSelectedPlayer.firstname,
      foundSelectedPlayer.email,
      foundSelectedPlayer.phone,
      selectedPlayerMemberStatus + 2,
      selectedYear
    );
  };

  const handleSelectedDateInputChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleSelectPlayerMemberDateConfirm = () => {
    api
    .get(`/PlayerMember/GetForYear?year=${selectedYear}`)
    .then((response) => {
      setPosts(response.data);
    })
    .catch(() => {
      
    });
  };

  const resetForm = () => {
    setFormError(null);
    setNachname("");
    setVorname("");
    setEmail("");
    setPhone("");
  };

  const handleAddNewMemberClick = (event) => {
    event.preventDefault();
    addSpezificMember(nachname, vorname, email, phone, selectedPlayerMemberStatus + 2, selectedYear)
  }

  const addSpezificMember = (pnachname, pvorname, pemail, pphone, pmemberStatus, pyear) => {
    startSuccessBarLoading("Spieler wird hinzugefügt");
    const requestBody = {
      lastname: pnachname ? pnachname : "",
      firstname: pvorname ? pvorname : "",
      email: pemail ? pemail : "",
      phone: pphone ? pphone : "",
      memberStatus: pmemberStatus ? pmemberStatus : "",
      year: pyear ? pyear : new Date().getFullYear()
    }

    api.post(`/PlayerMember/AddMember?firstname=${requestBody.firstname}&lastname=${requestBody.lastname}&email=${requestBody.email}&phone=${requestBody.phone}&memberStatus=${requestBody.memberStatus}&year=${requestBody.year}&password=${password}&applyForFutureEvents=${applyForFutureEvents}`)
      .then((response) => {
        if (response.data.length === 1) {
          startSuccessBarSuccess("Spieler hinzugefügt");
          const updatedPosts = [...posts, response.data[0]];
          setPosts(updatedPosts);
          resetForm();
        } else if (response.data.length > 1) {
          closeSuccessBar();
          setSelectePlayerData(response.data);
          openPopup('selectplayermember', 1);
        }
      })
      .catch(error => {
        closeSuccessBar();
        setFormError(error.response.data);
      });
  };

  const handleApplyForFutureEventsChange = (event) => {
    setApplyForFutureEvents(event.target.checked);
  };

  return (
    <React.Fragment>
      <Navbar usersSelected={true} extended={true} />
      <div className={"generalContainer"}>
        <MemberList 
          posts={posts} setPosts={setPosts} addMember={addSpezificMember} year={selectedYear} openYearSelect={openYearSelect}
        />
        <CreateForm onSubmit={handleAddNewMemberClick} icon="person_add" error={formError} tooltip={"Member oder Orga hinzufügen"}>
          <input value={nachname} onChange={e => setNachname(e.target.value)} type="text" placeholder="Nachname" name="lastname" />
          <input value={vorname} onChange={e => setVorname(e.target.value)} type="text" placeholder="Vorname" name="firstname" />
          <input value={email} onChange={e => setEmail(e.target.value)} type="text" placeholder="E-Mail" name="email" />
          <input value={phone} onChange={e => setPhone(e.target.value)} type="text" placeholder="Telefonnummer" name="phone" />
          <CustomComboBox placeholder='Member' selected={selectedPlayerMemberStatus} onStatusChange={setSelectedPlayerMemberStatus} type={"createform"}>
            <StatusDot type='member' statusId={2} />
            <StatusDot type='member' statusId={3} />
          </CustomComboBox>
          {selectedPlayerMemberStatus >= 1 ?
            <input required value={password} onChange={e => setPassword(e.target.value)} type="password" placeholder="Temporäres Passwort" name="password" /> : ""
          }
          <CustomTogglebox onChange={handleApplyForFutureEventsChange} checked={applyForFutureEvents} placeholder={'Für künftige Spieltage übernehmen'} />
        </CreateForm>
      </div>
      <Popup name='selectplayermember' confirmText={'Ok'} type="warning" withCancel>
        <p className={"selectPlayerTitle"}>Es wurden mehrere Spieler gefunden. Welcher Spieler soll hinzugefügt werden?</p>
        <div className={'selectPlayerWrapper'}>
          <ListHeader gridTemplateColumns={'repeat(2, 1fr)'} size={'small'}>
            <ListTextItem isHeader size={"small"}>Nach</ListTextItem>
            <ListTextItem isHeader size={"small"}>Vor</ListTextItem>
          </ListHeader>
          {selectPlayerData && 
            selectPlayerData.map((selectedPlayer, index) => (
              <ListItem 
                key={selectedPlayer.id}
                identifier={selectedPlayer.id}
                gridTemplateColumns={'repeat(2, 1fr)'}
                memo={selectedPlayer.memo}
                index={index}
                size={'small'}
                onClick={handleAddSelectedPlayerClick}
              >
                <ListTextItem>{selectedPlayer.lastname}</ListTextItem>
                <ListTextItem>{selectedPlayer.firstname}</ListTextItem>
              </ListItem>
            )
          )}
        </div>
      </Popup>
      <Popup name='selectplayermemberyear' confirmText={'Ok'} withCancel onConfirm={handleSelectPlayerMemberDateConfirm}>
        <p className={"selectPlayerMemberTitle"}>Für welches Jahr möchtest du die Memberliste laden?</p>
        <input type="number" placeholder="Datum" min={2024} className="selectPlayerMemberDate" name="selectPlayerMemberDate" onChange={handleSelectedDateInputChange} value={selectedYear} />
      </Popup>
    </React.Fragment>
  );
}

export default Member;