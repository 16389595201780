import React, { useState } from 'react'
import '../styles/MainContainer.css';
import StatusDot from '../status/StatusDot';
import ListHeader from '../list/ListHeader';
import ListItem from '../list/ListItem';
import ListTextItem from '../list/ListTextItem';
import ContextMenu from '../ContextMenu/ContextMenu';
import ContextMenuItem from '../ContextMenu/ContextMenuItem';
import ContextMenuSeparator from '../ContextMenu/ContextMenuSeparator';
import { useContextMenu } from '../context/ContextMenuContext';
import CustomComboBox from '../input/CustomComboBox';
import Popup from '../popup/Popup';
import { usePopup } from '../context/PopupContext';
import { useSuccessBar } from '../context/SuccessBarContext';
import api from '../wrapper/API';
import "../popup/EditPopup.css";
import { useNavigate } from 'react-router-dom';

function MemberList({posts, setPosts, year, openYearSelect = () => {}}) {
  const {startSuccessBarLoading, startSuccessBarSuccess, closeSuccessBar} = useSuccessBar();
  const { contextMenuData } = useContextMenu();
  const { openPopup, popupData } = usePopup();
  const [selectedPlayerMemberStatus, setSelectedPlayerMemberStatus] = useState(0);
  const [error, setError] = useState("");
  const [selectedPlayerHasPassword, setSelectedPlayerHasPassword] = useState(false);
  const [selectedPassword, setSelectedPassword] = useState("");
  const navigate = useNavigate();

  const editPlayerMember = () => {
    const postIndex = posts.findIndex(post => post.playerId === contextMenuData.identifier);
    setSelectedPlayerHasPassword(posts[postIndex].hasPassword);
    openPopup("editplayermember", contextMenuData.identifier)
  };

  const openPlayerWindowFromContextMenu = () => {
    navigate(`/users/${contextMenuData.identifier}`);
  };

  const applyForNextYear = () => {
    startSuccessBarLoading("Spieler-Status wird übernommen");
    const requestBody = {
      playerId: contextMenuData.identifier,
      currentYear: year,
      applyForFutureEvents: true
    }

    let requestLink = `/PlayerMember/ApplyForNextYear?playerId=${requestBody.playerId}&currentYear=${requestBody.currentYear}&applyForFutureEvents=${requestBody.applyForFutureEvents}`;

    api.post(requestLink)
      .then(() => {
        startSuccessBarSuccess("Spieler-Status übernommen");
      })
      .catch(error => {
        closeSuccessBar();
        setError(error.response.data);
        openPopup("playermembererror", requestBody.playerId);
      })
  };

  const removePlayerMember = () => {
    openPopup("warningdeleteplayermember", contextMenuData.identifier);
  };

  const handleEditPlayerMemberConfirm = () => {
    startSuccessBarLoading("Änderungen werden übernommen");
    const requestBody = {
      playerId: popupData.identifier,
      memberStatus: selectedPlayerMemberStatus + 2,
      year: year,
      password: selectedPlayerMemberStatus === 0 ? "" : selectedPassword,
      applyForFutureEvents: true
    }

    let requestLink = `/PlayerMember/EditMember?playerId=${requestBody.playerId}&memberStatus=${requestBody.memberStatus}&year=${requestBody.year}&memberStatus=${requestBody.memberStatus}&applyForFutureEvents=${requestBody.applyForFutureEvents}`;
    if (requestBody.password !== "") requestLink += `&password=${requestBody.password}`;

    api.put(requestLink)
      .then((response) => {
        startSuccessBarSuccess("Änderungen übernommen");
        const updatedPosts = [...posts];
        const postIndex = updatedPosts.findIndex(post => post.playerId === requestBody.playerId);
        updatedPosts[postIndex].memberStatus = response.data.memberStatus;
        setPosts(updatedPosts);
      })
      .catch(error => {
        closeSuccessBar();
        setError(error.response.data);
        openPopup("playermembererror", requestBody.playerId);
      })
  };

  const handleDeletePlayerMemberConfirm = () => {
    startSuccessBarLoading("Spieler-Status wird gelöscht");
    const requestBody = {
      playerId: popupData.identifier,
      year: year,
      applyForFutureEvents: true
    }

    let requestLink = `/PlayerMember/DeleteForYear?playerId=${requestBody.playerId}&year=${requestBody.year}&applyForFutureEvents=${requestBody.applyForFutureEvents}`;

    api.delete(requestLink)
      .then(() => {
        startSuccessBarSuccess("Spieler-Status gelöscht");
        const updatedPosts = posts.filter(post => post.playerId !== requestBody.playerId);
        setPosts(updatedPosts);
      })
      .catch(error => {
        closeSuccessBar();
        setError(error.response.data);
        openPopup("playermembererror", requestBody.playerId);
      })
  };

  return (
    <React.Fragment>
      <main className={posts.lenth > 0 ? "extendedMain hasPosts" : "extendedMain"}>
        <ListItem 
          key={year}
          identifier={year}
          gridTemplateColumns={'repeat(1, 1fr)'}
          memo={""}
          index={-1}
          onClick={openYearSelect}
        >
          <ListTextItem isHeader>Jahr: {year}</ListTextItem>
        </ListItem>
        <ListHeader gridTemplateColumns={'repeat(3, 1fr)'}>
          <ListTextItem isHeader>Member Status</ListTextItem>
          <ListTextItem isHeader>Nachname</ListTextItem>
          <ListTextItem isHeader>Vorname</ListTextItem>
        </ListHeader>
        {posts.map((post, index) => (
          <React.Fragment>
            <ListItem 
              key={post.playerId}
              identifier={post.playerId}
              gridTemplateColumns={'repeat(3, 1fr)'}
              memo={""}
              contextMenuIdentifier='playermember'
              index={index}
              onClick={() => {}}
            >
              <StatusDot type='member' statusId={post.memberStatus} />
              <ListTextItem>{post.lastname}</ListTextItem>
              <ListTextItem>{post.firstname}</ListTextItem>
            </ListItem>
          </React.Fragment>
        ))}
      </main>
      <ContextMenu type={'playermember'}>
        <ContextMenuItem label={'Bearbeiten'} icon={'edit'} type={0} onClick={editPlayerMember} />
        <ContextMenuItem label={'Öffnen'} icon={'folder_open'} type={0} onClick={openPlayerWindowFromContextMenu} />
        <ContextMenuSeparator />
        <ContextMenuItem label={'Für nächstes Jahr übernehmen'} icon={'add'} type={2} onClick={applyForNextYear} />
        <ContextMenuSeparator />
        <ContextMenuItem label={'Entfernen'} icon={'delete_sweep'} type={1} onClick={removePlayerMember} />
      </ContextMenu>
      <Popup name='editplayermember' confirmText={'Übernehmen'} onConfirm={handleEditPlayerMemberConfirm} withCancel type="info">
        <h3>Member Status</h3>
        <CustomComboBox placeholder='Member' selected={selectedPlayerMemberStatus} onStatusChange={setSelectedPlayerMemberStatus} type={"createform"}>
          <StatusDot type='member' statusId={2} />
          <StatusDot type='member' statusId={3} />
        </CustomComboBox>
        {selectedPlayerMemberStatus > 0 && !selectedPlayerHasPassword &&
          <input type='password' required className={"textInput"} placeholder='Temporäres Passwort' value={selectedPassword} onChange={(event) => setSelectedPassword(event.target.value)} />
        }
      </Popup>
      <Popup name='warningdeleteplayermember' confirmText='Bestätigen' headerText='Warnung'
        type="warning" onConfirm={handleDeletePlayerMemberConfirm} withCancel
      >
        <p>Du bist dabei, dem Spieler seinen Memberstatus für das Jahr {year} wegzunehmen. Die Änderung wird auch für künftige Spieltage übernommen. Möchtest du fortfahren?</p>
      </Popup>
      <Popup name='playermembererror' confirmText='Ok' headerText='Error' type="error">
        <p>{error}</p>
      </Popup>
    </React.Fragment>
  )
}

export default MemberList